import { createI18n } from 'vue-i18n';

const messages = {
    en: {
        main: {
            titleSection: {
                title: {
                    titleA: 'Meeting you has been ',
                    titleB: 'one of my luckiest moments.'
                },
                description: 'The best digital fashion and lovely experiences live here.'
            },
            descriptionSection: {
                title: 'Digital experiences, always like a lovely gift.',
                description: {
                    descriptionA: 'LIFFY innovates the digital fashion and character industry.',
                    descriptionB: 'We continuously push the boundaries of our contents by embracing new challenges, improving our services and expanding our contents to various platforms to captivate an even larger fan base.'
                }
            },
            serviceSection: {
                title: 'Our products and services',
                service: {
                    serviceA: 'Brand Store',
                    serviceB: 'Brand Experience',
                    serviceC: 'Brand Influencer'
                }
            }
        },
        about: {
            titleSection: {
                title: {
                    titleA: 'Communicating through ',
                    titleB: 'cordial design.'
                },
                description: 'Just like our classmates who love to decorate, we create our own fashion items.'
            },
            storySection: {
                title: 'LIFFY Story',
                description: {
                    paragraphA: {
                        senA: 'LIFFY started with one small, adorable idea.',
                        senB: 'Just as you would give a friend a four-leaf clover to wish them luck,',
                        senC: 'We wanted to put good luck into the items we make!'
                    },
                    paragraphB: {
                        senA: 'Our items, our design, our mascot Lily, and the adorable stories that go along with them.',
                        senB: 'All of our contents are born out of this wish.'
                    },
                    paragraphC: {
                        senA: 'We want to grow into a brand that brings love and good fortune to everyone who meets LIFFY.'
                    }
                }
            },
            brandSection: {
                title: 'What makes LIFFY Special?',
                description: {
                    paragraphA: {
                        senA: 'We live in the age of the metaverse, the world of digital twins.',
                        senB: 'It has become possible to create virtual items digitally or implement virtual reality, which is a hot keyword that is currently gaining a lot of attention from the public.',
                    },
                    paragraphB: {
                        senA: 'But who is enjoying that world the most?',
                        senB: 'What kind of content can be truly loved?'
                    },
                    paragraphC: {
                        senA: `What's unique about LIFFY is that we are directly engaging with the people who live in the digital world and those co-creating the world they want to live in.`,
                        senB: `Based on our key audiences who love our content, we're always looking for more ways to make them happier.`
                    }
                }
            },
            history: {
                title: 'Our Footprints',
                Aug2024: {
                    hisA: 'Roblox X Apple Korea X LIFFY: Created Promotional UGC'
                },
                Jul2024: {
                    hisA: 'Pond Store group renames to LIFFY'
                },
                Jun2024: {
                    hisA: 'Dance for UGC reached 20 million visits'
                },
                May2024: {
                    hisA: 'Slickdeals X Pond Store : Cross-Promotional UGC Creation'
                },
                Apr2024: {
                    hisA: 'Studdy X Pond Store : Cross-Promotional UGC Creation'
                },
                Feb2024: {
                    hisA: `Pond Store releases 'Dance for UGC'`
                },
                Jan2024: {
                    hisA: `Hyundai Universe game 'Hyundai Future Adventure' UGC Creation`
                },
                May2023: {
                    hisA: 'Brand mascot Lily is created'
                },
                Mar2023: {
                    hisA: `Pond Studio game 'Make It Louder' UGC Creation`
                },
                Mar2022: {
                    hisA: 'Pond Store group is created'
                },
                Dec2021: {
                    hisA: 'GS Retail X Rolbox Official Campaign UGC Creation'
                },
                Jun2021: {
                    hisA: 'Selected as Official Roblox UGC Creator'
                }
            }
        },
        business: {
            titleSection: {
                title: {
                    titleA: 'Together, the possibilities ',
                    titleB: 'are endless.'
                },
                description: `Join us as we take on new challenges that we're passionate about.`
            },
            solutionSection: {
                title: 'Roblox Virtual Item Solutions',
                subTitle: `Enhance your brand's value with LIFFY.`,
                solA: {
                    title: 'Dynamic Head',
                    description: {
                        paragraphA: {
                            senA: `Released in October 23, Roblox's Dynamic Head is a new head UGC item with facial animations and color changes.`
                        },
                        paragraphB: {
                            senA: 'We have the technology to create dynamic heads that fit any body type, and we are releasing dynamic heads with various concepts to meet the needs of the market.',
                        }
                    }
                },
                solB: {
                    title: 'Layered Clothes',
                    description: {
                        paragraphA: {
                            senA: `Launched in April 22, Roblox's Layered Clothes are clothes that transform to fit different avatar body types and existing layered items.`
                        },
                        paragraphB: {
                            senA: 'We have a long history of creating layered outfits to meet the needs of the market and a wide range of body types, and we are releasing the most optimized layered outfit UGCs for each avatar type.',
                        }
                    }
                },
                solC: {
                    title: '3D Accessories',
                    description: {
                        paragraphA: {
                            senA: '3D Accessories are the basic 3D items in Roblox and are divided into 8 types, including hats, hair, etc.'
                        },
                        paragraphB: {
                            senA: 'Based on our long experience in creating items, we have idntified the demand points of the accessory market and are releasing the most optimized accessory items for the marketplace.',
                        }
                    }
                },
                solD: {
                    title: 'Classic Clothes',
                    description: {
                        paragraphA: {
                            senA: 'The Classic Clothes is a basic 2D outfit in Roblox that is divided into top and bottom items.'
                        },
                        paragraphB: {
                            senA: 'Based on our long experience in creating costumes, we understands the demand points of the classic costume market and have the technology to create classic costumes that can respond to any concept.',
                        }
                    }
                }
            },
            liffySection: {
                description: 'LIFFY creates clothes that reflect the latest trends while adding cute charm to your wardrobe. Stay ahead of fashion trends with LIFFY!',
                chips: {
                    chipA: '#Trendy',
                    chipB: '#Cute',
                    chipC: '#Beloved',
                }
            },
            itemsSection: {
                title: 'Make adorable virtual items',
                description: `Visit the digital fashion store that embodies LIFFY's brand values.`
            },
            brandSection: {
                title: 'Brand Collaboration Cases',
                description: 'Achieve business success on Roblox with ease. Check out the cross-promotion case of LIFFY below!',
                colA: `We planned and produced three branded UGCs, Triangle Kimbap Cape, Omori Kimchi Stew Ramen Belt, and Gat (Korean Traditional Hat), which were reward items for the official 'Digital Citizenship Campaign' of Roblox, which was organized by GS Retail and Roblox.`,
                colB: `We organized a UGC collaboration event with the Roblox experience 'Hyundai Future Adventure'. LIFFY's marketing channels were utilized and Pom Boots w/ Bunny Legwarmer (Hyundai Blue) was created as a reward for pledges aimed at increasing the engagement of the experience.`,
                colC: `We collaborated with Slickdeals, the largest community-driven shopping sharing application in the US, to cross-promote UGC. LIFFY’s marketing channels were utilized and a Summer Sailor Dress (Sky Blue) UGC item was created as a reward for the event. `,
                colD: `LIFFY planned and produced a UGC for the Korean Gonryongpo Jacket, a layered clothes that was featured on the Apple Korea App Store by Roblox. LIFFY's item was promoted on the main page of the Korean app store, ROBLOX, and was in high demand among Korean users.`,
                colE: `We collaborated with Studdy, an educational application that helps students study based on AI. Our marketing channels were utilized and 2 branded UGC items, Cropped Knit Cardigan (Studdy Yellow) and Chibi Doll Sailor Dress (Studdy Yellow), were created as rewards for the event.`
            },
            socialSection: {
                title: 'Social Marketing Solutions',
                description: 'Bringing communities and brands together through engaging experiences.'
            },
            asof: '* Data as of 2024',
            trustedBy: 'Trusted by'
        },
        policy: {
            title: 'LIFFY Privacy Policy',
            policy00_intro: {
                desc: `LIFFY (hereinafter referred to as 'the Company') establishes and discloses the following personal information processing guidelines in accordance with Article 30 of the Personal Information Protection Act to protect users' personal information and to handle related grievances promptly and smoothly.`
            },
            policy_ar1: {
                title: `Article 1 (Purpose of Processing Personal Information)`,
                desc: `The Company processes personal information for the following purposes. The personal information being processed will not be used for any purpose other than the following, and if the purpose of use is changed, detailed information will be provided and user consent will be obtained in accordance with Article 18 of the Personal Information Protection Act.`,
                ul: {
                    ul_1: {
                        desc: `· Service Provision: For purposes such as providing services, content, and identity verification.`
                    },
                    ul_2: {
                        desc: `· Business Partnership Inquiries: For purposes such as verifying personal information and securing communication channels for business partnership inquiries and applications.`
                    },
                    ul_3: {
                        desc: `· Others: For purposes such as delivering advertising information like events, developing new services and providing customized services, verifying service effectiveness, and compiling statistics on service usage.`
                    }
                }
            },
            policy_ar2: {
                title: `Article 2 (Items of Personal Information Processed and Collection Methods)`,
                desc: `The Company collects the minimum personal information through applications, websites, inquiry calls, etc. as follows. There are no restrictions on the use of games and services even if optional information is not provided.`,
                ul: {
                    ul_1: {
                        desc: `· Business Partnership Inquiries`,
                        details: {
                            detail_1: {
                                title:  `    - (Required) Company name, name, email address, phone number`
                            },
                            detail_2: {
                                title:  `    - (Optional) Department, position`
                            }
                        }
                    },
                    ul_2: {
                        desc: `· Content Usage`,
                        details: {
                            detail_1: {
                                title: `    - Game play content, user ID, in-app purchase items and trends, in-app executions, tab clicks, viewed ads`
                            }
                        }
                    },
                    ul_3: {
                        desc: `· Content Diagnostic Data`,
                        details: {
                            detail_1: {
                                title: `    - Crash logs, execution time, non-responsiveness data`,
                            }
                        }
                    },
                    ul_4: {
                        desc: `· Information Automatically Collected During Internet Service Use`,
                        details: {
                            detail_1: {
                                title: `    - IP address, cookies, service usage records, visit records, records of misuse`,
                            }
                        }
                    }
                }
            },
            policy_ar3: {
                title: `Article 3 (Processing and Retention Period of Personal Information)`,
                desc: `In principle, personal information is destroyed without delay once the purpose of its collection and use is achieved. However, if it is necessary to retain information in accordance with relevant laws, the Company retains member information for a certain period as stipulated by the relevant laws.`,
                ul: {
                    ul_1: {
                        desc: `· Business Partnership Inquiries`,
                        details: {
                            detail_1: {
                                title: `    - Retention Period: 1 month after review completion`,
                            },
                            detail_2: {
                                title: `    - Legal Basis: User consent (Personal Information Protection Act)`
                            }
                        }
                    },
                    ul_2: {
                        desc: `· Materials for Verifying Communication under the Protection of Communications Secrets Act, Internet log records and tracking data of users`,
                        details: {
                            detail_1: {
                                title: '- Retention Period: 3 months'
                            },
                            detail_2: {
                                title: `    - Legal Basis: Protection of Communications Secrets Act`,
                            }

                        }
                    },
                    ul_3: {
                        desc: `· Other Communication Verification Data`,
                        details: {
                            detail_1: {
                                title: `    - Retention Period: 1 year`
                            },
                            detail_2: {
                                title: `    - Legal Basis: Protection of Communications Secrets Act`,
                            }
                        }
                    }
                }
            },
            policy_ar4: {
                title: `Article 4 (Provision of Personal Information to Third Parties) `,
                desc: `The Company processes personal information within the scope specified in Article 1 (Purpose of Processing Personal Information) and provides personal information to third parties only if the user consents or if there are special provisions in the law. However, personal information may be provided after review in the following cases:`,
                ul: {
                    ul_1: {
                        desc: `1. When the user has given prior consent`
                    },
                    ul_2: {
                        desc: `2. When data provision is mandatory by law or requested by the court, investigation agencies, or other administrative agencies through due process`
                    },
                    ul_3: {
                        desc: `3. When necessary for fee settlement following service provision`,
                    },
                    ul_4: {
                        desc: `4. When provided to third parties in a form that does not identify specific individuals`,
                    }
                }
            },
            policy_ar5: {
                title: `Article 5 (Rights and Obligations of Data Subjects and Their Exercise Methods)`,
                ul: {
                    ul_1: {
                        desc: `1.     Data subjects can exercise the following personal information protection rights at any time against the Company:`,
                        details: {
                            detail_1: {
                                title: `1. Request to view personal information`,
                            },
                            detail_2: {
                                title: `2. Request correction if there are errors`,
                            },
                            detail_3: {
                                title: `3. Request deletion`
                            },
                            detail_4: {
                                title:  `4. Request to stop processing`
                            }
                        }
                    },
                    ul_2: {
                        desc: `2.     The rights under Paragraph 1 can be exercised through written, phone, or email communication with the Company, and the Company will take action without delay.`,
                    },
                    ul_3: {
                        desc: `3.     If a data subject requests correction or deletion of personal information errors, the Company will not use or provide the relevant personal information until the correction or deletion is completed.`,
                    },
                    ul_4: {
                        desc: `4.     The rights under Paragraph 1 can also be exercised through the legal representative of the user or an authorized agent. In this case, a power of attorney must be submitted as per the form specified in the Personal Information Protection Act.`,
                    },
                    ul_5: {
                        desc: `5.     Users must not infringe on their own or others' personal information and privacy processed by the Company in violation of the Personal Information Protection Act or related laws.`,
                    }
                }
            },
            policy_ar6: {
                title: `Article 6 (Destruction of Personal Information)`,
                ul: {
                    ul_1: {
                        desc: `1.     The Company destroys personal information without delay when the retention period expires or the processing purpose is achieved.`
                    },
                    ul_2: {
                        desc: `2.     If the retention period agreed upon by the data subject expires or the processing purpose is achieved but the information needs to be preserved according to other laws, the information will be stored in a separate database or kept in a different storage location.`,
                    },
                    ul_3: {
                        desc: `3.     If there is no longer a need to provide app experiences or services to the user (when it is determined that the information is not essential for functionality), if the linked SNS account is deleted, or if requested by the linked service provider, the information will be deleted immediately.`,
                    },
                    ul_4: {
                        desc: `4.     The procedure and method for destroying personal information are as follows: `,
                        details: {
                            detail_1: {
                                title:  `                1.     Destruction Procedure`,
                                desc: `                    User information is destroyed without delay after the retention period expires, the collection and use purposes are achieved, or the user withdraws from membership.`
                            },
                            detail_2: {
                                title:  `                2.     Destruction Method`,
                                desc: `                    Electronic files containing personal information are permanently deleted to prevent reproduction, and paper documents are shredded or incinerated.`
                            }
                        }
                    }
                }
            },
            policy_ar7: {
                title: `Article 7 (Matters Concerning the Installation, Operation, and Rejection of Automatic Personal Information Collection Devices)`,
                ul: {
                    ul_1: {
                        desc: `1.     The Company uses 'cookies' to store and retrieve user information from time to time to provide individualized and customized services.`
                    },
                    ul_2: {
                        desc: `2.     Cookies are small amounts of information sent from a website's server to a user's computer browser and stored on the user's hard disk.`,
                        details: {
                            detail_1: {
                                title:  `· Purpose of Using Cookies: To understand the visit and usage patterns of each service and website visited by users, popular search terms, and security connections, to provide optimized information to users.`
                            },
                            detail_2: {
                                title:  `· Installation, Operation, and Rejection of Cookies: Users can refuse to store cookies through options settings in the browser's privacy menu.`
                            },
                            detail_3: {
                                title:  `· If cookies are rejected, there may be difficulties in using customized services.`,
                                linkA: `- Cookie Settings for Microsoft Edge:`,
                                linkB: `- Cookie Settings for Safari:`,
                                linkC: `- Cookie Settings for FireFox:`,
                                linkD: `- Cookie Settings for Chrome Browser:`,
                                confirm: 'Check'
                            }
                        }
                    }
                }
            },
            policy_ar8: {
                title: `Article 8 (Personal Information Protection Officer)`,
                ul: {
                    ul_1: {
                        desc: `1.     The Company has designated the following person to be responsible for overseeing personal information processing and handling complaints and damage relief related to personal information processing.`,
                        details: {
                            detail_1: {
                                title: `Personal Information Protection Officer`
                            },
                            detail_2: {
                                title: `Name : HyungGyu Kim`
                            },
                            detail_3: {
                                title: `Position : CEO`
                            },
                            detail_4: {
                                title: `Contact : +82 31-332-9992`
                            }
                        }
                    }
                },
                desc: `Users can direct all personal information protection-related inquiries, complaints, and damage relief requests that occur while using the Company's services to the Personal Information Protection Officer. The Company will respond to and handle user inquiries without delay.`
            },
            policy_ar9: {
                title: `Article 9 (Request for Viewing Personal Information)`,
                desc: `Users can request to view personal information under Article 35 of the Personal Information Protection Act through the department below. The Company will endeavor to promptly process the user's request to view personal information.`,
                ul: {
                    ul_1: {
                        desc: `Personal Information Protection Officer`
                    },
                    ul_2: {
                        desc: `Name : HyungGyu Kim`
                    },
                    ul_3: {
                        desc: `Position : CEO`
                    },
                    ul_4: {
                        desc: `Contact : +82 31-332-9992`
                    }
                }
            },
            policy_ar10: {
                title: `Article 10 (Measures to Ensure the Security of Personal Information)`,
                desc: `The Company takes the following measures to ensure the security of personal information:`,
                ul: {
                    ul_1: {
                        desc: `①     Managerial Measures: Establishment and implementation of internal management plans, training for employees and those handling personal information`,
                    },
                    ul_2: {
                        desc: `②     Technical Measures: Management of access rights to personal information processing systems, installation of security programs`,
                    }
                }
            },
            policy_ar11: {
                title: `Article 11 (Methods of Remedy for Infringement of Rights)`,
                desc: `Data subjects can contact the following organizations for damage relief, consultation, etc., regarding personal information infringement.`,
                ul: {
                    ul_1: {
                        desc: `· Personal Information Infringement Report Center (operated by Korea Internet &Security Agency)`,
                        details: {
                            detail_1: {
                                title:  `- Scope : Reporting and consulting on personal information infringement`
                            },
                            detail_2: {
                                title:  `- Website : `
                            },
                            detail_3: {
                                title:  `- Phone : 118 (without area code)`
                            },
                            detail_4: {
                                title:  `- Address : 3rd Floor, 9 Jinheung-gil, Naju-si, Jeollanam-do (Bitgaram-dong 301-2), 58324, South Korea`
                            }
                        }
                    },
                    ul_2: {
                        desc: `· Personal Information Dispute Mediation Committee`,
                        details: {
                            detail_1: {
                                title:  `- Scope : Personal information dispute mediation applications, collective dispute mediation (civil resolution)`
                            },
                            detail_2: {
                                title:  `- Website : `
                            },
                            detail_3: {
                                title:  `    - Phone : 1833-6972 (without area code)`
                            },
                            detail_4: {
                                title:  `    - Address : 4th Floor, Government Complex Seoul, 209 Sejong-daero, Jongno-gu, Seoul, 03171, South Korea`
                            }
                        }
                    },
                    ul_3: {
                        desc: `· Supreme Prosecutors' Office Cybercrime Investigation Division`,
                        details: {
                            detail_1: {
                                title:  `- Website : `
                            },
                            detail_2: {
                                title:  `- Phone : 02-3480-3573`
                            }
                        }
                    },
                    ul_4: {
                        desc: `· Cyber Safety Bureau of the National Police Agency`,
                        details: {
                            detail_1: {
                                title:  `- Website : `
                            },
                            detail_2: {
                                title:  `    - Phone : 182 (without area code)`
                            }
                        }
                    }
                }
            },
            policy_ar12: {
                title: `Article 12 (Changes to the Personal Information Processing Policy)`,
                ul: {
                    ul_1: {
                        desc: `1.     This personal information processing policy will take effect from July 31, 2024.`
                    }
                }
            }
        }
    },
    kr: {
        main: {
            titleSection: {
                title: {
                    titleA: 'Meeting you has been ',
                    titleB: 'one of my luckiest moments.'
                },
                description: '최고의 디지털 패션과 사랑스러운 경험의 콜라보레이션을 만나보세요.'
            },
            descriptionSection: {
                title: '언제나 사랑스러운 디지털 경험과의 만남',
                description: {
                    descriptionA: 'LIFFY는 디지털 패션과 캐릭터 산업을 혁신하는 브랜드입니다.',
                    descriptionB: '우리는 지속적인 새로운 도전으로 콘텐츠의 경계를 허물고, 더 많은 팬들에게 사랑받을 수 있도록 끊임없이 서비스를 개선하고 다양한 플랫폼으로 콘텐츠를 확장하기 위해 노력하고 있습니다.'
                }
            },
            serviceSection: {
                title: '제품과 서비스 살펴보기',
                service: {
                    serviceA: '브랜드 스토어',
                    serviceB: '브랜드 체험',
                    serviceC: '브랜드 인플루언서'
                }
            }
        },
        about: {
            titleSection: {
                title: {
                    titleA: 'Communicating through ',
                    titleB: 'cordial design.'
                },
                description: '꾸미기를 좋아하는 우리반 친구처럼, 우리만의 패션 아이템 - LIFFY.'
            },
            storySection: {
                title: 'LIFFY 스토리',
                description: {
                    paragraphA: {
                        senA: 'LIFFY는 작고 사랑스러운 하나의 아이디어에서 시작됐습니다.',
                        senB: '친구의 행운을 바라며 네잎클로버를 선물하는 것처럼, ',
                        senC: '우리가 만드는 아이템들에도 행운을 담아보자!'
                    },
                    paragraphB: {
                        senA: 'LIFFY의 아이템과 디자인, 마스코트 릴리, 그리고 그와 연관된 사랑스러운 스토리까지',
                        senB: '우리의 모든 콘텐츠들은 그런 바람으로부터 탄생했습니다.'
                    },
                    paragraphC: {
                        senA: '소중한 모든 만남에 우리의 소망을 담아 임하고, 이로써 LIFFY를 만나는 모든 이들에게 사랑과 행운을 선사하는 브랜드로 성장하고자 합니다.'
                    }
                }
            },
            brandSection: {
                title: 'LIFFY만의 특별함',
                description: {
                    paragraphA: {
                        senA: '우리는 메타버스의 시대, 디지털 트윈의 세상에 살고있습니다.',
                        senB: '디지털에서 가상 아이템을 만들거나 가상 현실을 구현하는것이 가능해졌고, 이는 현재 대중들에게 많은 관심을 받고있는 인기 키워드입니다.',
                    },
                    paragraphB: {
                        senA: '그러나 과연 그 세상을 가장 많이 향유하고 있는 대상은 누구일까요?',
                        senB: '어떤 콘텐츠들이 진정으로 사랑받을 수 있을까요?'
                    },
                    paragraphC: {
                        senA: `LIFFY의 특별한 점은 디지털 세상을 향유하는 사용자들과 직접 소통하며 그들이 바라는 세상을 함께 그려가고 있다는 점입니다.`,
                        senB: `우리 콘텐츠를 사랑하는 주요 타겟층이 누구인가를 기반으로, 언제나 그들을 더 행복하게 만들 수 있는 더 많은 방법을 찾기위해 끊임없이 고민합니다.`
                    }
                }
            },
            history: {
                title: '남겨온 흔적',
                Aug2024: {
                    hisA: 'Roblox X Apple Korea X LIFFY 프로모션 가상 아이템 제작'
                },
                Jul2024: {
                    hisA: 'Pond Store 그룹, LIFFY로 그룹명 변경'
                },
                Jun2024: {
                    hisA: 'Dance for UGC 2000만 방문자 수 달성'
                },
                May2024: {
                    hisA: 'Slickdeals X Pond Store : 크로스 프로모션 가상 아이템 제작'
                },
                Apr2024: {
                    hisA: 'Studdy X Pond Store : 크로스 프로모션 가상 아이템 제작'
                },
                Feb2024: {
                    hisA: `Pond Store 게임 Dance for UGC 게임 출시`
                },
                Jan2024: {
                    hisA: `Hyundai Universe 게임 Hyundai Future Adventure의 가상 아이템 제작`
                },
                May2023: {
                    hisA: '마스코트 릴리 탄생'
                },
                Mar2023: {
                    hisA: `Pond Studio 게임 Make It Louder의 가상 아이템 제작`
                },
                Mar2022: {
                    hisA: 'Pond Store 그룹 탄생'
                },
                Dec2021: {
                    hisA: 'GS 리테일 X 로블록스 공식 캠페인 가상 아이템 제작'
                },
                Jun2021: {
                    hisA: '로블록스 공식 UGC 크리에이터로 선정'
                }
            }
        },
        business: {
            titleSection: {
                title: {
                    titleA: 'Together, the possibilities ',
                    titleB: 'are endless.'
                },
                description: `LIFFY의 열정을 담은 새로운 도전들에 함께하세요.`
            },
            solutionSection: {
                title: 'Roblox 가상 아이템 솔루션',
                subTitle: `LIFFY와 함께 브랜드의 가치를 더하세요.`,
                solA: {
                    title: '다이나믹 헤드',
                    description: {
                        paragraphA: {
                            senA: '23년 10월에 출시된 로블록스의 다이나믹 헤드는 페이셜 애니메이션 및 색상 변경이 가능한 신기능이 추가된 헤드 UGC 아이템입니다.'
                        },
                        paragraphB: {
                            senA: 'LIFFY는 어떤 체형에든 잘 맞는 다이나믹 헤드 제작 기술을 갖추고 있으며, 마켓의 니즈에 맞는 다양한 콘셉트의 다이나믹 헤드들을 출시하고 있습니다.'
                        }
                    }
                },
                solB: {
                    title: '레이어드 의상',
                    description: {
                        paragraphA: {
                            senA: `22년 4월에 출시된 로블록스의 레이어드 의상은 다양한 아바타 체형과 기존 레이어드 아이템들에 꼭 맞도록 변형되는 옷입니다.`
                        },
                        paragraphB: {
                            senA: 'LIFFY는 오랜 레이어드 의상 제작 경험을 통해 마켓의 니즈와 다양한 체형에 맞는 아이템을 제작하고 있으며, 아바타의 타입별로 가장 최적화된 레이어드 의상 UGC들을 출시하고 있습니다.',
                        }
                    }
                },
                solC: {
                    title: '3D 악세서리',
                    description: {
                        paragraphA: {
                            senA: '3D 악세서리는 로블록스의 기본적인 3D 아이템으로 모자, 헤어 등 총 8개의 타입으로 구분되어 있습니다.'
                        },
                        paragraphB: {
                            senA: 'LIFFY는 오랜 아이템 제작 경험을 바탕으로 악세서리 시장의 수요 포인트를 파악하고, 마켓플레이스에 가장 최적화된 악세서리 아이템들을 출시하고 있습니다.',
                        }
                    }
                },
                solD: {
                    title: '클래식 의상',
                    description: {
                        paragraphA: {
                            senA: '클래식 의상은 로블록스의 기본적인 2D 의상으로 상의와 하의 아이템으로 구분되어 있습니다.'
                        },
                        paragraphB: {
                            senA: 'LIFFY는 오랜 의상 제작 경험을 바탕으로 클래식 의상 시장의 수요 포인트를 파악하고, 어떤 콘셉트에도 대응 가능한 클래식 의상 제작 기술을 갖추고 있습니다.',
                        }
                    }
                }
            },
            liffySection: {
                description: 'LIFFY는 최신 트렌드를 반영하면서도, 옷장에 귀여운 매력을 더해주는 옷들을 제작합니다. LIFFY와 함께 패션 트렌드를 앞서가세요!',
                chips: {
                    chipA: '#트렌디한',
                    chipB: '#귀여운',
                    chipC: '#사랑스러운',
                }
            },
            itemsSection: {
                title: '가상 아이템을 더욱 사랑스럽게',
                description: `LIFFY의 브랜드 가치가 담긴 디지털 패션 스토어에 방문하세요.`
            },
            brandSection: {
                title: '브랜드 협업 사례',
                description: '아주 간단하게 로블록스에서 성공적인 비즈니스를 펼쳐보세요. 아래에서 LIFFY의 크로스 프로모션 사례를 확인하세요!',
                colA: `LIFFY는 GS 리테일과 로블록스가 함께 진행한 로블록스 공식 '디지털 시민의식 캠페인'의 보상 아이템인 Triangle Kimbap Cape, Omori Kimchi Stew Ramen Belt, Gat (Korean Traditional Hat) 총 3종의 브랜드 UGC 기획 및 제작을 진행하였습니다.`,
                colB: `LIFFY는 로블록스 체험 '현대 퓨쳐 어드벤처'와 UGC 콜라보 이벤트를 진행하였습니다. LIFFY의 마케팅 채널이 활용되었으며 체험의 인게이지먼트 상승을 목표로 한 공약의 보상으로 Pom Boots w Bunny Leg Warmer (Hyundai Blue) UGC 아이템을 제작하였습니다.`,
                colC: `LIFFY는 미국 최대의 커뮤니티 중심 쇼핑 공유 어플리케이션인 Slickdeals와 콜라보하여 UGC 크로스 프로모션을 진행하였습니다. LIFFY의 마케팅 채널이 활용되었으며 이벤트의 보상으로 Summer Sailor Dress (Sky Blue) UGC 아이템을 제작하였습니다.`,
                colD: `LIFFY는 로블록스가 애플 코리아 앱스토어에서 메인으로 소개한 레이어드 의상 Korean Gonryongpo Jacket UGC를 기획하고 제작하였습니다. 대한민국 앱스토어 로블록스 메인 페이지에서 LIFFY의 아이템이 홍보되었으며 한국 유저 중심으로 폭발적인 수요가 있었습니다.`,
                colE: `LIFFY는 인공지능 기반으로 학생들의 공부를 보조해주는 교육 어플리케이션인 Studdy와 콜라보하여 UGC 크로스 프로모션을 진행하였습니다. LIFFY의 마케팅 채널이 활용되었으며 이벤트의 보상으로 Cropped Knit Cardigan(Studdy Yellow), Chibi Doll Sailor Dress(Studdy Yellow) 총 2종의 브랜드 UGC를 제작하였습니다.`
            },
            socialSection: {
                title: '소셜 마케팅 솔루션',
                description: '매력적인 경험과 함께라면 커뮤니티와 브랜드를 하나로 모을 수 있습니다.'
            },
            asof: '* 2024 기준 데이터',
            trustedBy: '파트너스'
        },
        policy: {
            title: 'LIFFY 개인정보 처리방침',
            policy00_intro: {
                desc: `LIFFY (이하 '회사')는 개인정보 보호법 제30조에 따라 이용자의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리지침을 수립․공개합니다.`
            },
            policy_ar1: {
                title: `제1조(개인정보의 처리목적)`,
                desc: `회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 상세히 안내 후 이용자로부터 동의를 얻습니다.`,
                ul: {
                    ul_1: {
                        desc: `· 서비스 제공 : 서비스 제공, 콘텐츠 제공, 본인인증 등의 목적`
                    },
                    ul_2: {
                        desc: `· 사업제휴 문의 : 사업제휴, 제휴신청에 따른 인적사항 확인 및 의사소통 경로 확보`
                    },
                    ul_3: {
                        desc: `· 기타 : 이벤트 등 광고성 정보 전달에 활용, 신규서비스 개발 및 맞춤 서비스 제공, 서비스 유효성 확인, 이용자 서비스 이용에 대한 통계`
                    }
                }
            },
            policy_ar2: {
                title: `제2조(처리하는 개인정보 항목 및 수집 방법)`,
                desc: `회사는 어플리케이션이나 홈페이지, 문의 전화 등을 통해 아래와 같이 최소한의 개인정보를 수집하고 있습니다. 또한, 선택정보의 경우 입력하지 않아도 게임 및 서비스 이용에 제한이 없습니다.`,
                ul: {
                    ul_1: {
                        desc: `· 제휴문의`,
                        details: {
                            detail_1: {
                                title:  `    - (필수) 사명, 이름, 메일 주소, 전화 번호`
                            },
                            detail_2: {
                                title:  `    - (선택) 부서, 직급`
                            }
                        }
                    },
                    ul_2: {
                        desc: `· 콘텐츠 이용`,
                        details: {
                            detail_1: {
                                title: `    - 게임 플레이 콘텐츠 , 사용자 ID, 계정의 앱 내 구입 항목과 구입 경향 , 게임 내 앱 실행, 탭 클릭 시청한 광고 등에 대한 정보`
                            }
                        }
                    },
                    ul_3: {
                        desc: `· 콘텐츠 진단 데이터`,
                        details: {
                            detail_1: {
                                title: `    - 충돌 로그 , 실행 시간 , 무반응 데이터`,
                            }
                        }
                    },
                    ul_4: {
                        desc: `· 인터넷 서비스 이용과정에서 자동으로 수집되는 정보`,
                        details: {
                            detail_1: {
                                title: `    - IP, 쿠키(Cookie), 서비스 이용기록, 방문기록, 불량 이용기록`,
                            }
                        }
                    }
                }
            },
            policy_ar3: {
                title: `제3조(개인정보의 처리 및 보유기간)`,
                desc: `원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.`,
                ul: {
                    ul_1: {
                        desc: `· 사업제휴문의, 사업제휴 문의 정보 `,
                        details: {
                            detail_1: {
                                title: `    - 기간 : 검토완료 후 1개월`,
                            },
                            detail_2: {
                                title: `    - 근거법령 : 정보주체 동의(개인정보 보호법)`
                            }
                        }
                    },
                    ul_2: {
                        desc: `· 통신비밀보호법에 따른 통신사실 확인 자료, 이용자의 인터넷 등 로그기록/ 이용자의 접속지 추적자료`,
                        details: {
                            detail_1: {
                                title: '- 기간 : 3개월'
                            },
                            detail_2: {
                                title: `    - 근거법령 : 통신비밀보호법`
                            }

                        }
                    },
                    ul_3: {
                        desc: `· 그 외의 통신사실 확인 자료`,
                        details: {
                            detail_1: {
                                title: `    - 기간 : 1년`
                            },
                            detail_2: {
                                title: `    - 근거법령 : 통신비밀보호법`
                            }
                        }
                    }
                }
            },
            policy_ar4: {
                title: `제4조(개인정보의 제3자 제공) `,
                desc: `회사는 이용자의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 이용자의 동의가 있거나, 법률에서 허용하는 특별한 사유가 있는 경우에 한하여 개인정보를 제3자에게 제공합니다. 다만 아래의 경우에는 검토를 거친 후 개인정보를 제공할 수 있습니다.`,
                ul: {
                    ul_1: {
                        desc: `1.     이용자가 사전에 동의한 경우 `
                    },
                    ul_2: {
                        desc: `2.     법령의 규정에 의거하여 자료 제공이 강제되는 경우 또는 적법한 절차에 의한 법원, 수사기관, 기타 행정기관의 요청이 있는 경우`
                    },
                    ul_3: {
                        desc: `3.     서비스 제공에 따른 요금 정산을 위해 필요한 경우 `,
                    },
                    ul_4: {
                        desc: `4.     특정 개인을 식별할 수 없는 형태로 제3자에게 제공하는 경우`,
                    }
                }
            },
            policy_ar5: {
                title: `제5조(정보주체의 권리․의무 및 행사방법)`,
                ul: {
                    ul_1: {
                        desc: `1.     정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.`,
                        details: {
                            detail_1: {
                                title: `                1.     개인정보 열람요구`,
                            },
                            detail_2: {
                                title: `                2.     오류 등이 있을 경우 정정 요구`,
                            },
                            detail_3: {
                                title: `                3.     삭제요구`
                            },
                            detail_4: {
                                title: `                4.     처리정지 요구`
                            }
                        }
                    },
                    ul_2: {
                        desc: `2.     제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편 등을 통하여 하실 수 있으며 회사는 이용자의 요청에 대해 지체없이 조치하겠습니다.`,
                    },
                    ul_3: {
                        desc: `3.     정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.`,
                    },
                    ul_4: {
                        desc: `4.     제1항에 따른 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.`,
                    },
                    ul_5: {
                        desc: `5.     이용자는 개인정보 보호법 등 관계법령을 위반하여 회사가 처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니됩니다.`,
                    }
                }
            },
            policy_ar6: {
                title: `제6조(개인정보의 파기)`,
                ul: {
                    ul_1: {
                        desc: `1.     회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.`
                    },
                    ul_2: {
                        desc: `2.     정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.`,
                    },
                    ul_3: {
                        desc: `3.     더 이상 사용자에게 앱 경험이나 서비스를 제공할 필요가 없을 경우(기능 구현에 필수적으로 필요한 정보가 아닌 것으로 판단되는 경우), 연계된 SNS 계정을 삭제하는 경우, 계정정보를 연계하는 해당 업체의 요청이 있는 경우, 법이나 규정에 의해 필요한 경우, 해당 정보는 즉시 삭제됩니다.`,
                    },
                    ul_4: {
                        desc: `4.     개인정보 파기의 절차 및 방법은 다음과 같습니다. `,
                        details: {
                            detail_1: {
                                title:  `                1.     파기절차`,
                                desc: `                    이용자의 회원정보는 고지 및 동의 받은 기간 동안 보유하고 이용하며, 개인정보의 수집 및 이용목적이 달성되거나 보유기간 만료, 회원 탈퇴 시 지체 없이 파기하는 것을 원칙으로 합니다`
                            },
                            detail_2: {
                                title:  `                2.     파기방법`,
                                desc: `                    회사는 전자적 파일 형태로 기록・저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록・저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.`
                            }
                        }
                    }
                }
            },
            policy_ar7: {
                title: `제7조(개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항)`,
                ul: {
                    ul_1: {
                        desc: `1.     회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.`
                    },
                    ul_2: {
                        desc: `2.     쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.`,
                        details: {
                            detail_1: {
                                title:  `· 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.`
                            },
                            detail_2: {
                                title:  `· 쿠키의 설치∙운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.`
                            },
                            detail_3: {
                                title:  `· 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.`,
                                linkA: `- Microsoft Edge를 사용하는 경우 쿠키 설정 방법:`,
                                linkB: `- Safari를 사용하는 경우 쿠키 설정 방법:`,
                                linkC: `- FireFox를 사용하는 경우 쿠키 설정 방법:`,
                                linkD: `- Chrome 브라우저를 사용하는 경우 쿠키 설정 방법:`,
                                confirm: '확인하기'
                            }
                        }
                    }
                }
            },
            policy_ar8: {
                title: `제8조(개인정보 보호책임자)`,
                ul: {
                    ul_1: {
                        desc: `1.     회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.`,
                        details: {
                            detail_1: {
                                title: `개인정보 보호책임자`
                            },
                            detail_2: {
                                title: `성명 : 김형규`
                            },
                            detail_3: {
                                title: `직책 : 대표`
                            },
                            detail_4: {
                                title: `연락처 : 031-332-9992`
                            }
                        }
                    }
                },
                desc: `이용자께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자에게 문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.`
            },
            policy_ar9: {
                title: `제9조(개인정보 열람청구)`,
                desc: `이용자는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.`,
                ul: {
                    ul_1: {
                        desc: `개인정보 보호책임자`
                    },
                    ul_2: {
                        desc: `성명 : 김형규`
                    },
                    ul_3: {
                        desc: `직책 : 대표`
                    },
                    ul_4: {
                        desc: `연락처 : 031-332-9992`
                    }
                }
            },
            policy_ar10: {
                title: `제10조(개인정보의 안전성 확보조치)`,
                desc: `회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.`,
                ul: {
                    ul_1: {
                        desc: `① 관리적 조치: 내부관리계획 수립·시행, 임직원 및 개인정보취급자 교육`,
                    },
                    ul_2: {
                        desc: `② 기술적 조치: 개인정보처리시스템 등의 접근권한 관리, 보안프로그램 설치 등`,
                    }
                }
            },
            policy_ar11: {
                title: `제11조(권익침해 구제방법)`,
                desc: `정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.
                <아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다>`,
                ul: {
                    ul_1: {
                        desc: `· 개인정보 침해신고센터 (한국인터넷진흥원 운영)`,
                        details: {
                            detail_1: {
                                title:  `- 소관업무 : 개인정보 침해사실 신고, 상담 신청`
                            },
                            detail_2: {
                                title:  `- 홈페이지 : `
                            },
                            detail_3: {
                                title:  `- 전화 : (국번없이) 118`
                            },
                            detail_4: {
                                title:  `- 주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해신고센터`
                            }
                        }
                    },
                    ul_2: {
                        desc: `· 개인정보 분쟁조정위원회`,
                        details: {
                            detail_1: {
                                title:  `- 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)`
                            },
                            detail_2: {
                                title:  `- 홈페이지 : `
                            },
                            detail_3: {
                                title:  `- 전화 : (국번없이) 1833-6972`
                            },
                            detail_4: {
                                title:  `- 주소 : (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층`
                            }
                        }
                    },
                    ul_3: {
                        desc: `· 대검찰청 사이버범죄수사단`,
                        details: {
                            detail_1: {
                                title:  `- 홈페이지 : `
                            },
                            detail_2: {
                                title:  `- 전화 : 02-3480-3573`
                            }
                        }
                    },
                    ul_4: {
                        desc: `· 경찰청 사이버안전국`,
                        details: {
                            detail_1: {
                                title:  `- 홈페이지 : `
                            },
                            detail_2: {
                                title:  `- 전화 : (국번없이) 182`
                            }
                        }
                    }
                }
            },
            policy_ar12: {
                title: `제12조(개인정보 처리방침 변경)`,
                ul: {
                    ul_1: {
                        desc: `1. 이 개인정보 처리방침은 2024. 07. 31부터 적용됩니다.`
                    }
                }
            }
        }
    }
};

const i18n = createI18n({
  locale: 'en', // 기본 언어 설정
  messages,
});

export default i18n;

<template>
  <div>
    <div class="d-flex w-100 flex-column bs_trusted_bg">
      <div class="container bs_engaging_container">

        <div class="bs_git_titleArea">
          <img :src="flowerSVG" class="bs_trusted_icon"/>
          <img :src="flowerSVG" class="bs_trusted_icon bs_git_tilt bs_trusted_mo"/>
          <img :src="flowerSVG" class="bs_trusted_icon bs_trusted_tab"/>
          <p class="bs_trusted_title">Trusted by</p>
          <img :src="flowerSVG" class="bs_trusted_icon bs_trusted_tab"/>
          <img :src="flowerSVG" class="bs_trusted_icon bs_git_tilt bs_trusted_mo"/>
          <img :src="flowerSVG" class="bs_trusted_icon"/>
        </div>

        <div class="bs_trusted_area">
          <ul class="bs_trusted_list">
            <li v-for="(a, i) in TrustedBrandList" :key="i" class="bs_trusted_brands" :style="{ backgroundImage: `url(${a.logo})` }"></li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GetInTouch',
  data(){
    return{
      flowerSVG: require('../../assets/icon/flower.svg'),
      TrustedBrandList: [
        {
          id: 1,
          logo: require('../../assets/image/brandLogo_l/logo_apple.png')
        },
        {
          id: 2,
          logo: require('../../assets/image/brandLogo_l/logo_gs.png')
        },
        {
          id: 3,
          logo: require('../../assets/image/brandLogo_l/logo_roblox.png')
        },
        {
          id: 4,
          logo: require('../../assets/image/brandLogo_l/logo_hyundai.png')
        },
        {
          id: 5,
          logo: require('../../assets/image/brandLogo_l/logo_slickdeals.png')
        },
        {
          id: 6,
          logo: require('../../assets/image/brandLogo_l/logo_studdy.png')
        }
      ]
    }
  }
}
</script>

<style>
.bs_trusted_bg {
  background-color: #083400;
  padding: 64px 0px;
}
.bs_trusted_title {
  color: #87ea5c;
  font-size: 35px;
  font-weight: 700;
  width: 360px;
  height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 40px;
}
.bs_trusted_icon {
  filter: invert(72%) sepia(38%) saturate(626%) hue-rotate(56deg) brightness(105%) contrast(93%);
  width: 30px;
  height: 30px;
  margin: auto 20px;
}
.bs_trusted_area {
  margin-top: 40px;
}
.bs_trusted_list {
  padding-left: 0;
  margin-bottom: 0;
}
.bs_trusted_brands {
  width: calc((100% - 30px) / 3);
  height: 56px;
  margin: 8px 5px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  opacity: 0.5;
}
@media screen and (max-width: 490px) {
  .bs_trusted_bg {
    padding: 32px 0px;
  }
  .bs_trusted_title {
    width: 176px;
    height: 36px;
    font-size: 15px;
    border-radius: 25px;
    margin: auto 16px;
  }
  .bs_trusted_icon {
    width: 20px;
    height: 20px;
    margin: auto 0px;
  }
  .bs_trusted_area {
    margin-top: 24px;
  }
  .bs_trusted_brands {
    height: 24px;
    margin: 4px 4px;
  }
}
</style>
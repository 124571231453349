<template>
  <TopNavigation />
  <router-view></router-view>
  <Footer />
</template>

<script>
import TopNavigation from './components/common/TopNavigation.vue'
import Footer from './components/common/Footer.vue';
import axios from 'axios';

// axios.get()
// axios.post()

export default {
  name: 'App',
  components: {
    TopNavigation,
    Footer
  },
  data() {      // 확인을 위해 추가한 부분
    return {
      fetchData: null
    };
  },
  mounted() {
    // reCAPTCHA 스크립트 로드
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    this.more() // 확인을 위해 추가한 부분
  },
  methods: {
    more() {
      // axios.get('https://codingapple1.github.io/vue/more0.json')
      axios.get('db.alphonse.gabia.io', {
        headers: {
          // 'Authorization': 'Bearer YOUR_API_KEY'
        }
      })
      .then((result)=>{
        this.fetchData = result.data; // 확인을 위해 추가한 부분
        // console.log(result.data);
        console.log(this.fetchData);  // 확인을 위해 추가한 부분
      })
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Albert+Sans:ital,wght@0,100..900;1,100..900&display=swap');
#app {
  font-family: "Albert Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  line-height: normal;
  color: #083400;
  margin-top: 60px;
}
</style>
